import React, { useState, useEffect } from "react"
import type { ComponentType } from "react"

let resetFunctions = []
let formState = {}

export function withForm(Component): ComponentType {
    return (props) => {
        return <Component {...props} />
    }
}

export function withInputField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        return (
            <input
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif", // Set the font to Inter.
                    border: "none", // Remove the border.
                    padding: "15px",
                    borderRadius: "8px",
                    outline: "none",
                }}
                placeholder=""
            />
        )
    }
}

export function withTextAreaField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        return (
            <textarea
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif",
                    border: "none",
                    padding: "15px",
                    borderRadius: "8px",
                    resize: "none", // Usually we don't want textarea to be resizable
                    outline: "none",
                }}
                placeholder=""
            />
        )
    }
}

export function withDropdownField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        // Hardcoded options
        const options = [
            { value: "abruzzo", label: "Abruzzo" },
            { value: "basilicata", label: "Basilicata" },
            { value: "calabria", label: "Calabria" },
            { value: "Campania", label: "Campania" },
            { value: "emilia-romagna", label: "Emilia_Romagna" },
            { value: "friuli-venezia-giulia", label: "Friuli-venezia Giulia" },
            { value: "lazio", label: "Lazio" },
            { value: "liguria", label: "Liguria" },
            { value: "lombardia", label: "Lombardia" },
            { value: "marche", label: "Marche" },
            { value: "molise", label: "Molise" },
            { value: "piemonte", label: "Piemonte" },
            { value: "puglia", label: "Puglia" },
            { value: "sardegna", label: "Sardegna" },
            { value: "sicilia", label: "Sicilia" },
            { value: "toscana", label: "Toscana" },
            { value: "trentino-alto-adige", label: "Trentino-Alto Adige" },
            { value: "umbria", label: "Umbria" },
            { value: "Valle d'aosta", label: "Valle d'Aosta" },
            { value: "veneto", label: "Veneto" },
            // more options...
        ]

        return (
            <select
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif",
                    border: "none",
                    padding: "15px",
                    borderRadius: "8px",
                    outline: "none",
                }}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        )
    }
}

export function withCheckboxField(Component): ComponentType {
    return (props) => {
        const [checked, setChecked] = useState(false)

        useEffect(() => {
            resetFunctions.push(() => setChecked(false))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setChecked
                )
            }
        }, [])

        const handleChange = (event) => {
            setChecked(event.target.checked)
            formState[props.name] = event.target.checked
        }

        return (
            <label
                style={{
                    display: "block",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "12px",
                }}
            >
                <input
                    {...props}
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    style={{
                        marginRight: "0px",
                    }}
                />
                {props.label}
            </label>
        )
    }
}

export function withSubmitButton(Component): ComponentType {
    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            const response = await fetch(
                "https://hooks.zapier.com/hooks/catch/1930288/3iegs0v/",
                {
                    method: "POST",
                    body: JSON.stringify(formState),
                    headers: {
                        "Content-Type": "text/plain",
                    },
                }
            )

            if (response.ok) {
                console.log("Form successfully submitted")
                resetFunctions.forEach((reset) => reset())
            } else {
                console.log("Form submission failed")
            }
        } catch (error) {
            console.error("Error submitting form: ", error)
        }
    }

    return (props) => {
        return (
            <button
                {...props}
                onClick={handleSubmit}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent", // Changed the background color to blue here.
                    border: "none",
                }}
            ></button>
        )
    }
}
